<template>
  <div class="flex flex-col">
    <div class="p-3 flex items-center">
      <div class="w-3/12 flex mobile:w-6/12">{{ company.company_name }}</div>
      <div class="w-5/12 flex items-center mobile:w-6/12 mobile:flex-col">
        <span class="mr-2">Klient ühendatud profiiliga:</span>
        <span>{{ company.confirmed ? "Jah" : "Ei" }}</span>
        <button
          class="new-button-green"
          v-if="!company.confirmed"
          @click="merge"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Ühenda konto</span>
        </button>
      </div>
    </div>
    <div class="flex w-full h-1 bg-offwhite"></div>
  </div>
</template>

<script>
export default {
  name: "CompanyCard",
  props: {
    company: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    merge() {
      this.$emit("merge", this.company.company_uuid);
    }
  }
};
</script>
