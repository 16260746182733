<template>
  <div class="">
    <div v-if="companiesLoaded && companies.length > 0">
      <h2>Seotud ettevõtted</h2>

      <company-card
        class="flex"
        v-for="company in companies"
        :key="company.company_uuid"
        :company="company"
        @merge="mergeClient"
      ></company-card>
    </div>
    <div v-if="companiesLoaded && companies.length === 0">
      <h2>Seotud ettevõtted</h2>

      <span>Teie kontoga ei ole seotud ühtegi ettevõtet</span>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import CompanyCard from "@/components/client/CompanyCard";

export default {
  name: "Companies",
  data() {
    return {
      companies: [],
      companiesLoaded: false
    };
  },
  components: { CompanyCard },
  mixins: [RequestHandler],
  mounted() {
    this.retrieveData();
  },
  methods: {
    mergeClient(e) {
      this.apiRequest("company/" + e + "/merge/", "put", true).then(res => {
        if (res) {
          this.retrieveData();
        }
      });
    },
    retrieveData() {
      this.apiRequest(
        "profiles/" + this.$store.state.userData.user.id + "/clients/",
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          this.companies = res.data;
        }
        this.companiesLoaded = true;
      });
    }
  }
};
</script>
