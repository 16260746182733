<template>
  <companies></companies>
</template>

<script>
import Companies from "@/components/client/Companies";
export default {
  components: {
    Companies
  }
};
</script>
